.personItem{
  margin: 12px;
  padding: 12px;
  background: #ffffff;
  display: flex;
  align-items: flex-start;

  &_row1{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_cellTitle{
    color: #4D4D4D;

  }
  &_createDate{
    font-size: 14px;
    color: #808080;
  }

  &_row2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #F5F9F9;
    border-radius: 4px;
    margin-top: 10px;

  }
}