
.groupItem {
  margin: 10px;
  border-radius: 6px;
  background: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0, 199, 187, 0.2);
  padding: 10px;
}

.groupItemRow{
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 10px;

  & span{
    margin-left: 5px;
  }

  &_dynamic{
    flex: 1;
    display: flex;
    flex-direction: column;

    & span{
      margin-bottom: 3px;
    }
  }
}

.groupItemRow:last-child{
  margin-bottom: 0;
}