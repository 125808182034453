.map{
  height: 370px;
}

.detail{
  background-color: #fff;
  margin: 12px 12px 0 12px;
  padding: 12px;
  border-radius: 4px;

  &__plotname{
    display: flex;
    align-items: center;
    

    span{
      margin-left: 5px;
      font-weight: bold;
    }
  }

  &__msgGroup{
    
    background: #F5F9F9;
    border-radius: 2px;
    margin: 10px 0;
    padding: 12px;
    font-size: 15px;
  }

  &__msgGroup:last-child{
    margin-bottom: 0;
  }

  &__tvCell{
    display: flex;
    margin-bottom: 10px;
  }

  &__tvCell:last-child{
    margin-bottom: 0;
  }

  &__title{
    color: #405656;
  }

  &__value{
    color: #1E2D2D;
    font-weight: bold;
    flex: 1;
  }
}