.stageItem{
  margin: 12px;

  &_name {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 12px;
    width: 100px;
    height: 22px;
    border-radius: 12px;
    background-color: #00AFB7;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.taskItem{
  
  &_divider{
    margin-left: 27px;
  }

  &_content{
    background: #fff;
    padding: 12px;
    display: flex;
  }

  &_cell{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 5px;
    margin-left: 5px;
  }

  &_name {
    color: #00898F;
    font-weight: bold;
    font-size: 15px;
    
  }
  
  &_intro {
    color: #999999;
    font-size: 13px;
    margin-top: 5px;
  }


  &_statusComplete {
    width: 60px;
    height: 22px;
    color: #FF7F00;
    background-color: #FFf2e6;
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  &_statusProceed {
    width: 60px;
    height: 22px;
    color: #00CBB3;
    background-color: #E6FAF7;
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}