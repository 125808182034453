.ppItem{
  background-color: #fff;
  margin:12px;
  padding:12px;
  border-radius: 4px;

  &__topflex{
    display: flex;
  }
  img{
    border-radius: 2px;
  }


  &__txtGroup{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
  }

  &__txtGroupTop{
    display: flex;
    flex-direction: column;
  }
  &__name{
    font-weight: bold;
  }

  &__create{
    color: #808080;
    font-size: 12px;
    margin-top: 7px;
  }

  &__txtGroupBottom{
    font-size: 13px;
    color: #4D4D4D;
  }

  &__degree{
    color: #027160;
    font-weight: bold;
    font-size: 15px;
  }
}