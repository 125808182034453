:root{
  --rv-tab-font-size:12px;
  --rv-tabs-line-height:65px;
}

.tabPaneTitle{
  display: flex;
  flex-direction :column;
  align-items: center;

  & span{
    line-height: 1;
  }
}