.woItem{
  background: #fff;
  margin: 12px;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 2px 10px 0 rgba(145, 212, 185, 0.26);

  &_row1{
    display: flex;
   align-items: center;
  }

  &_name{
    color: #00898F;
    font-weight: bold;
    font-size: 15px;
    flex: 1;
  }

  &_tag{
    margin: 0 10px;
  }

  &_createdate{
    color: #666666;
    font-size: 13px;
  }
}

