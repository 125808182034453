.plotItem{
  background-color: #fff;
  margin: 12px;
  border-radius: 4px;

  &__plotname{
    width: 125px;
    height: 30px;
    background: rgba(2,113,96,0.1);
    border-radius: 4px 0px;
    color: #027160;
    line-height: 30px;
    text-align: center;
  }

  &__title{
    color: #4D4D4D;
  }
  &__value{
    color: #000;
  }

}