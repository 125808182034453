.msg{
  background:#fff;
  padding:12px;

  &_tvCell{
    font-size: 15px;
    display: flex;
    margin-bottom: 10px;
  }

  &_title{
    color: #808080;
  }

  &_value{
    color: #000000;
    font-weight: bold;
    flex: 1;
  }


  &_tjCell{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
  
  &_tjNum{
    color: #00898F;
    font-weight: bold;
    font-size: 20px;
  }
  
  &_tjUnit{
    color: #00898F;
    font-size: 12px;
    margin-left: 1px;
    padding-bottom: 3px;
  }
  
  &_tjTitle {
    color: #999999;
    font-size: 13px;
    text-align: center;
  }
}

:root{
  --rv-floating-ball-size:70px;
}