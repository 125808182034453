:root{
  --rv-cell-background-color:'#fafafa';
}

.fuwenben{
  margin: 0 16px;

  & img{
    width: 100%;
  }
}