.container{
  margin:auto 24px ;
  padding-bottom: 80px;
}

.card{
  margin:12px;
}

.card:last-child{
  margin-bottom: 60px;
}



