:root{
  --rv-circle-text-font-size:16px
}

.manage{
  background: #fff;
  padding: 12px;

  &_flag{
    width: 3px;
    height: 15px;
    background: #027160;
    border-radius: 2px;
  }

  &_ppname{
    font-weight: bold;
    color: #161E2B ;
  }

  &__tvCell{
    font-size: 15px;
    display: flex;
    margin-top: 10px;
  }

  &__title{
    color: #808080;
  }

  &__value{
    color: #000000;
    font-weight: bold;
    flex: 1;
  }
}

.stage{
  overflow-y: auto;
}



