.logItem{
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(234,234,234,0.1);
  border-radius: 4px;
  margin:12px;
  padding:12px;

  &_name{
    font-size: 14px;
    font-weight: 500;
  }

  &_subDate{
    font-size: 12px;
    color: #808080;
  }

  &_text{
    width: calc(100vw - 50px);
    max-width: calc(1024px - 50px);
  }

  a{color:#3f45ff;cursor:pointer}
}