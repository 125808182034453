html{
  margin: 0 auto;
  font-size: 16px;
  background-color: #FAFAFA;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@media screen and (min-width: 1024px) {
  html {
    max-width: 1024px;
  }
}

/* 固定底部提交按钮 */
.btn_sub_bottom{
  position:fixed;
  bottom: 0;
  width: calc(100vw - 24px);
  max-width: calc(1024px - 24px);
}

:root {
  --lyy-main-color: #027160;
  --rv-button-primary-background-color: var(--lyy-main-color);
  --rv-button-primary-border-color: var(--lyy-main-color);
  --rv-tab-text-color:#CCCCCC;
  --rv-tab-active-text-color:var(--lyy-main-color);
  --rv-tabs-bottom-bar-color:var(--lyy-main-color);
  --rv-font-weight-bold:bold;
  --rv-tab-font-size:16px;
  --rv-button-small-height:25px;
  --rv-tag-line-height:20px;
}

/* color='#027160' titleActiveColor='#027160' titleInactiveColor='#CCCCCC' */