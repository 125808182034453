.direction{
  margin: 12px;
  background-color: #fff;
  border-radius: 4px;
}

.collaboration{
  margin: 12px;
  padding: 12px;
  background: #fff;
}

:root{
  --rv-typography-primary-color:#4D4D4D
}