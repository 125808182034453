.lv2row{
  display: flex;
  align-items: center;
  margin: 10px ;

  &_flag{
    width: 2px;
    height: 16px;
    margin-right: 8px;
    background: #0dc9b8;
    border-radius: 2px;
    box-shadow: 1px 0 3px 0 #00c7bb;
  }

  &_title{
    font-size: 14px;
    font-weight: bold;
  }
}